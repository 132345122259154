import {fetchAuthSession} from 'aws-amplify/auth'
import {useCallback, useEffect} from "react";

export const LandingPage = () => {
  const loadData = useCallback(async () => {
    const authSession = await fetchAuthSession()
    console.log('accessToken', authSession.tokens?.accessToken?.toString())
  }, [])

  useEffect(() => {
    loadData()
  })

  return <h1>Test</h1>
}