import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Amplify} from "aws-amplify";
import {signInWithRedirect, signOut} from "aws-amplify/auth";
import {LandingPage} from "./components/LandingPage";
import {Button} from "@aws-amplify/ui-react";
import {useAuth} from "@aws-amplify/ui-react/internal";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID!,
      userPoolClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID!,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_HOST!,
          scopes: [
            'email',
            'profile',
            'openid',
            'phone',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: [process.env.REACT_APP_REDIRECT_URL!],
          redirectSignOut: [process.env.REACT_APP_LOGOUT_CALLBACK!],
          responseType: 'code',
        }
      }
    }
  }
})

function App() {
  const auth = useAuth()
  const handleSignIn = () => {
    if (auth.user) {
      signOut()
    } else {
      signInWithRedirect({
        provider: "Google",
      })
    }
  }

  return (
    <div className="App">
      <LandingPage/>
      <Button onClick={handleSignIn}>{auth.user ? 'Sign out' : 'Sign in'}</Button>
      {auth.user?.username}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  )
}

export default App;
